<template>
  <div class="about">
    <h2 class="about-title">
      Découvrez l'histoire de la créatrice depuis 1998.
    </h2>
    <div class="about-img">
      <img src="../assets/_F3A5908.jpeg" alt="Pareena" />
      <h2>Découvrez l'histoire de la créatrice depuis 1998.</h2>
      <p>
        "PAREENA PI" a été créé par Pareena Noguès. D'origine thailandaise et
        fière de ses racines, elle veut refleter le monde contemporain.
        Voyageuse au cours de sa vie, elle vient transcender les cultures, en
        liant amour, écologie ainsi que la beauté de tous. Sous ses aires
        solaires, elle reflète autant le bonheur, la joie et la tristesse. Elle
        incarne pleinement l'imperfection.
      </p>
    </div>
    <p class="about-description">
      "PAREENA PI" a été créé par Pareena Noguès. D'origine thailandaise et
      fière de ses racines, elle veut refleter le monde contemporain. Voyageuse
      au cours de sa vie, elle vient transcender les cultures, en liant amour,
      écologie ainsi que la beauté de tous. Sous ses aires solaires, elle
      reflète autant le bonheur, la joie et la tristesse. Elle incarne
      pleinement l'imperfection.
    </p>
    <div class="about-meaning">
      <div class="about-meaning-img">
        <img src="../assets/logo.png" alt="Logo" />
      </div>
      <div class="about-meaning-text">
        <h2>Découvrez les significations de la marque ainsi que de son logo</h2>
        <p>
          <br /><br />
          "PAREENA PI" vous invite à rentrer dans un monde imparfaitement
          parfait et infini suivi d'aucune logique, montrant un changement
          constant.
          <br /><br />
          Nous, humains, nous traversons des épreuves au cours d'une vie, nos
          expériences construisent notre identité. Elles nous façonnent telle
          une pierre qui serait sculptée par les vagues salées. Ainsi le
          paradoxe parfait réside ici: le chiffre PI représente le rapport
          constant d'un cercle à son diamètre dans le monde réel. Or, le cercle
          est la forme parfaite par excellence.
          <br /><br />
          "PI" va incarner l'irrégularité de la prérfection, l'immatérialité de
          la constance et l'implacabilité de la beauté.
          <br /><br />
          Ce logo représente dans toute sa splendeur "l'humain" mettant en avant
          le nombre parfait et imparfait au centre du logo ainsi que la première
          lettre du nom de la créatrice, ornée du morse "imparfaitement
          imparfaite".
        </p>
      </div>
    </div>
    <div class="about-concept">
      <div class="pentagon">
        <!-- 
        <img src="../assets/about.jpeg" alt="Sable" /> -->
        <p class="about-concept-text">
          <span class="about-concept-text-span">LE CONCEPT</span><br /><br />
          La conception de la marque va mettre en avant : l’irrégularité de la
          perfection, l’immatérialité de la constance, l’impalpabilité de la
          beauté
          <br /><br />
          Alliant idée, conception, innovation et écologie, la marque « 
          PAREENA. PI » s’engage à recycler les matériaux déjà utilisés
          (plastique, verre, vieil or…), afin de pouvoir les associer avec des
          pierres précieuses et semi-précieuses : un design unique en son genre
          transcendant une histoire riche en caractère.
          <br /><br />
          Les bijoux ainsi conçus seront des éditions limitées, afin d’affiner
          leur personnalité.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
